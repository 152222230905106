
import { createApp, h } from 'vue';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router';
// import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import Vue3TouchEvents from "vue3-touch-events";
import {apolloProvider} from "./vue-apollo";

loadFonts()
const app = createApp({
    render() { return h(App) }
});

app.use(apolloProvider).use(vuetify).use(Vue3TouchEvents).use(router).mount('#app')
