<template>
  <div class="mainDiv">
    <link
      href="https://fonts.googleapis.com/css?family=Noto Sans"
      rel="stylesheet"
    />
    <div class="up" v-if="showpersonal">
      <!-- <img
        class="mainlogo"
        :src="require('@/assets/backarr.png')"
        alt="mylogo"
        @click="goback()"
      /> -->
      <i class="bi bi-arrow-left mainlogo" @click="goback()"></i>
      <!-- <span class="material-icons">arrow_back</span> -->
      <span class="adminhead">Personal Page</span>
    </div>
    <div v-if="showpage">
      <img
        :src="require('@/assets/NFCLogo.png')"
        alt="NoLogo"
        class="companylogo"
      /><br /><br />
      <h5><b>ByteNFC Modes</b></h5>
      <p>
        You can update mode by radio button to mark as default mode while tap
        your ByteNFC Card on the phone
      </p>

      <br /><br />
      <div class="containerDiv" v-for="i in 4" :key="i.id">
        <div class="mainElement" v-if="i == 1 && selectedNum === 1">
          <input
            type="radio"
            class="rbtn myradio"
            name="selectbtn"
            value="1"
            v-model="selectedbtn"
            @click="changeNum(i)"
            checked
          />
          <span class="s1 one">
            <h4>My byteCard</h4>
            <p class="rec">{{ byteCardUrl }}</p>
          </span>
        </div>
        <div class="mainElement" v-else-if="i == 1 && selectedNum !== 1">
          <input
            type="radio"
            class="rbtn myradio"
            name="selectbtn"
            value="1"
            v-model="selectedbtn"
            @click="changeNum(i)"
          />
          <span class="s1 one">
            <h4>My byteCard</h4>
            <p class="rec">{{ byteCardUrl }}</p>
          </span>
        </div>
        <div class="mainElement" v-if="i == 2 && this.selectedNum === 2">
          <input
            type="radio"
            class="rbtn radioalign myradio"
            name="selectbtn"
            value="2"
            @click="changeNum(i)"
            v-model="selectedbtn"
            checked
          />
          <span class="s1 two">
            <h4>Link To URL</h4>
            <p class="rec">{{ url }}</p>
            <v-row justify="center">
              <v-dialog v-model="dialog" persistent>
                <template v-slot:activator="{ props }">
                  <div class="down">
                    <i
                      v-bind="props"
                      class="bi bi-three-dots-vertical dotalign"
                    ></i>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">EDIT URL</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div class="content cont">
                          <br />
                          <v-col cols="12">
                            <v-text-field
                              class="tfw"
                              label="Enter URL"
                              v-model="url"
                              required
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue-darken-1" text @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn color="blue-darken-1" text @click="add2">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </span>
        </div>
        <div class="mainElement" v-else-if="i == 2 && selectedNum !== 2">
          <input
            type="radio"
            class="rbtn radioalign myradio"
            name="selectbtn"
            value="2"
            @click="changeNum(i)"
            v-model="selectedbtn"
          />
          <span class="s1 two">
            <h4>Link To URL</h4>
            <p class="rec">{{ url }}</p>
            <v-row justify="center">
              <v-dialog v-model="dialog" persistent>
                <template v-slot:activator="{ props }">
                  <div class="down">
                    <i
                      v-bind="props"
                      class="bi bi-three-dots-vertical dotalign"
                    ></i>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">EDIT URL</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div class="content cont">
                          <br />
                          <v-col cols="12">
                            <v-text-field
                              class="tfw"
                              label="Enter URL"
                              v-model="url"
                              required
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue-darken-1" text @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn color="blue-darken-1" text @click="add2">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </span>
        </div>
        <div class="mainElement" v-if="i == 3 && selectedNum === 3">
          <input
            type="radio"
            class="rbtn radioalign myradio"
            name="selectbtn"
            value="3"
            @click="changeNum(i)"
            v-model="selectedbtn"
            checked
          />
          <span class="s1 three">
            <h4>Personal Page</h4>
            <p class="rec">{{ arr1.length }} Options Added</p>
            <i
              class="bi bi-three-dots-vertical dotsalign"
              @click="showPersonalPage"
            ></i>
          </span>
        </div>
        <div class="mainElement" v-else-if="i == 3 && selectedNum !== 3">
          <input
            type="radio"
            class="rbtn radioalign myradio"
            name="selectbtn"
            value="3"
            @click="changeNum(i)"
            v-model="selectedbtn"
          />
          <span class="s1 three">
            <h4>Personal Page</h4>
            <p class="rec">{{ arr1.length }} Options Added</p>
            <!-- <img
              :src="require('@/assets/dots.png')"
              class="dots"
              @click="showPersonalPage"
            /> -->
            <i
              class="bi bi-three-dots-vertical dotsalign"
              @click="showPersonalPage"
            ></i>
          </span>
        </div>
        <div class="mainElement" v-if="i == 4 && selectedNum === 4">
          <span v-if="arr1.length === 0">
            <input
              type="radio"
              class="rbtn myradio"
              name="selectbtn"
              value="4"
              v-model="selectedbtn"
              disabled
            />
          </span>
          <span v-else>
            <input
              type="radio"
              class="rbtn myradio"
              name="selectbtn"
              value="4"
              @click="
                changeNum(i);
                show();
              "
              v-model="selectedbtn"
              checked
            />

            <span class="s1 four">
              <h4>Personal Page Item</h4>
              <p class="rec">Set One Default Option</p>
              <v-row justify="center">
                <v-dialog v-model="dialog2" persistent>
                  <template v-slot:activator="{ props }">
                    <div class="down">
                      <i
                        v-bind="props"
                        class="bi bi-three-dots-vertical dotalign"
                      ></i>
                    </div>
                  </template>
                  <v-card class="mycard">
                    <v-card-title>
                      <span class="text-h5">Select any One</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <div class="content cont">
                            <br />
                            <v-col cols="12">
                              <div
                                v-for="(i, index) in arr1"
                                :key="i.id"
                                class="outer"
                              >
                                <div
                                  class="main"
                                  v-if="
                                    arr1.length !== 0 &&
                                    arr1[index][0] == 'Whatsapp'
                                  "
                                >
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <th rowspan="2">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <td rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="bi bi-whatsapp"
                                                style="
                                                  color: #0e8403;
                                                  font-size: 25px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </td>
                                          <td>
                                            <span class="ss1">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="ss2">{{
                                              arr1[index][2]
                                            }}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div
                                  class="main"
                                  v-else-if="
                                    arr1.length !== 0 &&
                                    arr1[index][0] == 'Location'
                                  "
                                >
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <th rowspan="2">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <td rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="bi bi-geo-alt"
                                                style="
                                                  color: #002a68;
                                                  font-size: 25px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </td>
                                          <td>
                                            <span class="ss1">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="ss2">{{
                                              arr1[index][2]
                                            }}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div
                                  class="main lm"
                                  v-else-if="
                                    arr1.length !== 0 &&
                                    arr1[index][0] == 'Link'
                                  "
                                >
                                  <b-alert show class="point lpoint">
                                    <span>
                                      <table>
                                        <tr>
                                          <th rowspan="2" class="linkth">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <td rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="bi bi-link-45deg"
                                                style="
                                                  color: #df7800;
                                                  font-size: 28px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </tr>
                                        <tr>
                                          <span class="ss2">{{
                                            arr1[index][2]
                                          }}</span>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div
                                  class="main"
                                  v-else-if="
                                    arr1.length !== 0 &&
                                    arr1[index][0] == 'Call'
                                  "
                                >
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <th rowspan="2">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <td rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="bi bi-telephone"
                                                style="
                                                  color: #009083;
                                                  font-size: 25px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </td>
                                          <td>
                                            <span class="ss1">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="ss2">{{
                                              arr1[index][2]
                                            }}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div
                                  class="main"
                                  v-else-if="
                                    arr1.length !== 0 &&
                                    arr1[index][0] == 'Download'
                                  "
                                >
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <th rowspan="2">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <td rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="bi bi-download"
                                                style="
                                                  color: #888b00;
                                                  font-size: 25px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </td>
                                          <td>
                                            <span class="ss1">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="ss2"
                                              >My File Details</span
                                            >
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div
                                  class="main"
                                  v-else-if="
                                    arr1.length !== 0 &&
                                    (arr1[index][0] == 'Vcard' || arr1[index][0] == 'Save Contact')
                                  "
                                >
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <th rowspan="2">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <td rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="
                                                  bi bi-credit-card-2-front
                                                "
                                                style="
                                                  color: #7e0181;
                                                  font-size: 25px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </td>
                                          <td>
                                            <span class="ss1">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="ss2"
                                              >My Card Details</span
                                            >
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div
                                  class="main"
                                  v-else-if="
                                    arr1.length !== 0 &&
                                    arr1[index][0] == 'Email'
                                  "
                                >
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <th rowspan="2">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <th rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="bi bi-envelope"
                                                style="
                                                  color: #025f8d;
                                                  font-size: 25px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </th>
                                          <td>
                                            <span class="ss1">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="ss2">{{
                                              arr1[index][2]
                                            }}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div
                                  class="main"
                                  v-else-if="
                                    arr1.length !== 0 &&
                                    arr1[index][0] == 'Reviews'
                                  "
                                >
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <th rowspan="2">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <th rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="bi bi-star"
                                                style="
                                                  color: #025f8d;
                                                  font-size: 25px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </th>
                                          <td>
                                            <span class="ss1">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="ss2">{{
                                              arr1[index][2]
                                            }}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div
                                  class="main"
                                  v-else-if="
                                    arr1.length !== 0 &&
                                    arr1[index][0] == 'Website'
                                  "
                                >
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <th rowspan="2">
                                            <label class="l1">
                                              <input
                                                class="rbtn selectbtn"
                                                type="radio"
                                                name="myselect"
                                                :value="arr1[index]"
                                                v-model="selectedArr"
                                              />
                                            </label>
                                          </th>
                                          <th rowspan="2">
                                            <label class="l2">
                                              <i
                                                class="bi bi-globe"
                                                style="
                                                  color: #025f8d;
                                                  font-size: 25px;
                                                  color: #e0e0e0;
                                                "
                                              ></i>
                                            </label>
                                          </th>
                                          <td>
                                            <span class="ss1">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="ss2">{{
                                              arr1[index][2]
                                            }}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                                <div class="main" v-else-if="arr1.length !== 0">
                                  <b-alert show class="point point1">
                                    <span class="names">
                                      <table>
                                        <tr>
                                          <td>
                                            <span class="dis">{{
                                              arr1[index][1]
                                            }}</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="dis fsm">{{
                                              arr1[index][2]
                                            }}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </span>
                                  </b-alert>
                                </div>
                              </div>
                            </v-col>
                          </div>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="blue-darken-1"
                        text
                        @click="dialog2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn color="blue-darken-1" text @click="add2">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </span>
          </span>
        </div>
        <div class="mainElement" v-else-if="i == 4 && selectedNum !== 4">
          <span v-if="arr1.length === 0">
            <input
              type="radio"
              class="rbtn myradio"
              name="selectbtn"
              value="4"
              v-model="selectedbtn"
              disabled
            />
          </span>
          <span v-else>
            <input
              type="radio"
              class="rbtn myradio"
              name="selectbtn"
              value="4"
              @click="changeNum(i)"
              v-model="selectedbtn"
            />
          </span>
          <span class="s1 four">
            <h4>Personal Page Item</h4>
            <p class="rec">Set One Default Option</p>
            <v-row justify="center">
              <v-dialog v-model="dialog2" persistent>
                <template v-slot:activator="{ props }">
                  <div class="down">
                    <!-- <img
                      v-bind="props"
                      :src="require('@/assets/dots.png')"
                      class="dot"
                    /> -->
                    <i
                      v-bind="props"
                      class="bi bi-three-dots-vertical dotalign"
                    ></i>
                  </div>
                </template>
                <v-card class="mycard">
                  <div v-if="arr1.length === 0" class="noitem">
                    <p class="notext">No Page Items Added</p>
                  </div>
                  <v-card-title v-else>
                    <span class="text-h5">Select any One</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div class="content cont">
                          <br />
                          <v-col cols="12">
                            <div
                              v-for="(i, index) in arr1"
                              :key="i.id"
                              class="outer"
                            >
                              <div
                                class="main"
                                v-if="
                                  arr1.length !== 0 &&
                                  arr1[index][0] == 'Whatsapp'
                                "
                              >
                                <b-alert show class="point point1">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <th rowspan="2">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <td rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-whatsapp"
                                              style="
                                                color: #0e8403;
                                                font-size: 25px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </td>
                                        <td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="ss2">{{
                                            arr1[index][2]
                                          }}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div
                                class="main"
                                v-else-if="
                                  arr1.length !== 0 &&
                                  arr1[index][0] == 'Location'
                                "
                              >
                                <b-alert show class="point point1">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <th rowspan="2">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <td rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-geo-alt"
                                              style="
                                                color: #002a68;
                                                font-size: 25px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </td>
                                        <td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="ss2">{{
                                            arr1[index][2]
                                          }}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div
                                class="main"
                                v-else-if="
                                  arr1.length !== 0 && (arr1[index][0] == 'Vcard' || arr1[index][0] == 'Save Contact')
                                "
                              >
                                <b-alert show class="point point1">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <th rowspan="2">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <td rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-credit-card-2-front"
                                              style="
                                                color: #7e0181;
                                                font-size: 25px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </td>
                                        <td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="ss2"
                                            >My Card Details</span
                                          >
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div
                                class="main lm"
                                v-else-if="
                                  arr1.length !== 0 && arr1[index][0] == 'Link'
                                "
                              >
                                <b-alert show class="point lpoint">
                                  <span>
                                    <table>
                                      <tr>
                                        <th rowspan="2" class="linkth">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <td rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-link-45deg"
                                              style="
                                                color: #df7800;
                                                font-size: 28px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </td>
                                        <span class="ss1">{{
                                          arr1[index][1]
                                        }}</span>
                                      </tr>
                                      <tr>
                                        <span class="ss2">{{
                                          arr1[index][2]
                                        }}</span>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div
                                class="main"
                                v-else-if="
                                  arr1.length !== 0 && arr1[index][0] == 'Call'
                                "
                              >
                                <b-alert show class="point point1">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <th rowspan="2">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <td rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-telephone"
                                              style="
                                                color: #009083;
                                                font-size: 25px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </td>
                                        <td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="ss2">{{
                                            arr1[index][2]
                                          }}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div
                                class="main"
                                v-else-if="
                                  arr1.length !== 0 &&
                                  arr1[index][0] == 'Download'
                                "
                              >
                                <b-alert show class="point point1">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <th rowspan="2">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <td rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-download"
                                              style="
                                                color: #888b00;
                                                font-size: 25px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </td>
                                        <td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="ss2"
                                            >My File Details</span
                                          >
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div
                                class="main"
                                v-else-if="
                                  arr1.length !== 0 && arr1[index][0] == 'Email'
                                "
                              >
                                <b-alert show class="point point1">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <th rowspan="2">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <th rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-envelope"
                                              style="
                                                color: #025f8d;
                                                font-size: 25px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </th>
                                        <td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="ss2">{{
                                            arr1[index][2]
                                          }}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div
                                class="main"
                                v-else-if="
                                  arr1.length !== 0 &&
                                  arr1[index][0] == 'Website'
                                "
                              >
                                <b-alert show class="point point1">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <th rowspan="2">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <th rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-globe"
                                              style="
                                                color: #025f8d;
                                                font-size: 25px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </th>
                                        <td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="ss2">{{
                                            arr1[index][2]
                                          }}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div
                                class="main"
                                v-else-if="
                                  arr1.length !== 0 &&
                                  arr1[index][0] == 'Reviews'
                                "
                              >
                                <b-alert show class="point point1">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <th rowspan="2">
                                          <label class="l1">
                                            <input
                                              class="rbtn selectbtn"
                                              type="radio"
                                              name="myselect"
                                              :value="arr1[index]"
                                              v-model="selectedArr"
                                            />
                                          </label>
                                        </th>
                                        <th rowspan="2">
                                          <label class="l2">
                                            <i
                                              class="bi bi-star"
                                              style="
                                                color: #025f8d;
                                                font-size: 25px;
                                                color: #e0e0e0;
                                              "
                                            ></i>
                                          </label>
                                        </th>
                                        <td>
                                          <span class="ss1">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="ss2">{{
                                            arr1[index][2]
                                          }}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                              <div class="main" v-else-if="arr1.length !== 0">
                                <b-alert show class="point">
                                  <span class="names">
                                    <table>
                                      <tr>
                                        <td>
                                          <span class="dis">{{
                                            arr1[index][1]
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span class="dis fsm">{{
                                            arr1[index][2]
                                          }}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </span>
                                </b-alert>
                              </div>
                            </div>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue-darken-1" text @click="dialog2 = false">
                      Cancel
                    </v-btn>
                    <v-btn color="blue-darken-1" text @click="add2">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </span>
        </div>
        <!-- <div class="mainElement" v-else>
          <input
            type="radio"
            class="rbtn radioalign"
            name="selectbtn"
            value="4"
            v-model="selectedbtn"
            @click="show"
          />
        </div> -->
      </div>
    </div>
    <div v-if="showpersonal" class="myadmin">
      <adminOperation />
    </div>
  </div>
</template>
<script>
import adminOperation from "./adminOperation.vue";
export default {
  components: {
    adminOperation,
  },
  data() {
    return {
      selectedbtn: "",
      showpage: true,
      showpersonal: false,
      totalRec: 0,
      tempArr: [],
      arr1: [],
      dialog: false,
      dialog2: false,
      val: "",
      url: "https://www.example.com",
      selectedItem: "",
      selectedArr: [],
      selectedNum: 1,
      keyArr: ["type", "label", "value"],
      tempSelectedArr: [],
      newTempArr: [],
      byteCardUrl: "",
      myView: "",
    };
  },
  methods: {
    handleLogout() {
      localStorage.removeItem(this.$route.params.id);
      //this.$router.push('/' + this.$route.params.id + '/admin');
      //this.$router.push("/" + this.$route.params.id);
      var myHeaders = new Headers();
      myHeaders.append("token", "4765be9f-a47a-46e8-9105-49bc5654cf53");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch("https://api.bytecard.in/api/nfc/logout", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    changeNum(num) {
      this.selectedNum = num;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");
      var options;
      var i, j;
      if (this.arr1.length == 0) {
        options = '{"items":[]}';
      } else {
        options = '{"items":[';
        for (i = 0; i < this.arr1.length; i++) {
          options = options.concat('{"');
          for (j = 0; j < 3; j++) {
            if (j === 2) {
              options = options.concat(
                this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
              );
            } else {
              options =
                options.concat(
                  this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
                ) + ',"';
            }
          }
          if (i !== this.arr1.length - 1) {
            options = options.concat("},");
          } else {
            options = options.concat("}]}");
          }
        }
      }
      var myUrl = '"' + this.$route.params.id + '"';
      console.log(myUrl);

      console.log("Options ->" + options);
      var raw =
        '{\r\n"hwId": ' +
        myUrl +
        ',\r\n"action":{ "data":[{"type":"' +
        this.selectedArr[0] +
        '", "label":"' +
        this.selectedArr[1] +
        '", "value":"' +
        this.selectedArr[2] +
        '"}], "num":"' +
        this.selectedNum +
        '", "url":"' +
        this.url +
        '", "view":"' +
        this.myView +
        '"},\r\n"options":' +
        options +
        "}";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://api.bytecard.in/api/nfc/update", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      console.warn(raw);
    },
    show() {
      console.log("Kushal");
    },
    showPersonalPage() {
      this.showpage = false;
      this.showpersonal = true;
    },
    goback() {
      this.arr1 = [];
      this.getData();
      this.showpage = true;
      this.showpersonal = false;
    },
    add() {
      this.dialog = false;
    },
    add2() {
      this.dialog = false;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");
      var options;
      var i, j;
      if (this.arr1.length == 0) {
        options = '{"items":[]}';
      } else {
        options = '{"items":[';
        for (i = 0; i < this.arr1.length; i++) {
          options = options.concat('{"');
          for (j = 0; j < 3; j++) {
            if (j === 2) {
              options = options.concat(
                this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
              );
            } else {
              options =
                options.concat(
                  this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
                ) + ',"';
            }
          }
          if (i !== this.arr1.length - 1) {
            options = options.concat("},");
          } else {
            options = options.concat("}]}");
          }
        }
      }
      var myUrl = '"' + this.$route.params.id + '"';
      console.log(myUrl);

      console.log("Options ->" + options);
      var raw =
        '{\r\n"hwId": ' +
        myUrl +
        ',\r\n"action":{ "data":[{"type":"' +
        this.selectedArr[0] +
        '", "label":"' +
        this.selectedArr[1] +
        '", "value":"' +
        this.selectedArr[2] +
        '"}], "num":"' +
        this.selectedNum +
        '", "url":"' +
        this.url +
        '", "view":"' +
        this.myView +
        '"},\r\n"options":' +
        options +
        "}";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://api.bytecard.in/api/nfc/update", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      console.warn(raw);
      this.dialog2 = false;
    },
    getData() {
      var formdata = new FormData();
      console.log(this.arr1);
      //   console.log(this.$route.params.id);
      //   formdata.append("email", this.email);
      //   formdata.append("password", this.password);
      //   formdata.append("productId", "a9ec6071-ab8c-479e-b3d0-ee0cbfc68ae8");
      formdata.append("hwId", this.$route.params.id);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch("https://api.bytecard.in/api/nfc/get", requestOptions)
        .then((response) => response.text())
        .then((result) => this.printData(result))
        .catch((error) => alert(error));
    },
    printData(result) {
      var arr = JSON.parse(result);
      if (arr.status == false) {
        alert(arr.message);
        console.log(arr);
      } else {
        console.log(arr.data.options);
        //this.arr1 = Object.entries(arr.data.options);
        if (arr.data.options == null) {
          this.arr1 = [];
          this.selectedArr = [];
          this.byteCardUrl = arr.data.profile.bytecard;
        } else {
          this.tempArr = Object.values(arr.data.options.items);
          for (var i = 0; i < this.tempArr.length; i++) {
            this.arr1.push(Object.values(this.tempArr[i]));
          }
          //this.arr1 = [["Link", "Google", "https://www.google.com"], ["Call", "Home", "9106884674"], ["Call", "Office", "9879364472"], ["Whatsapp", "Office", "9106884674"], ["Whatsapp", "Home", "7878962975"], ["Location", "Location", "https://maps.app.goo.gl/vKBKrocP7XaeJFH19"], ["Whatsapp", "Shop", "9106884674"]],
          console.log(this.arr1);
          this.tempSelectedArr = Object.values(arr.data.action.data);
          for (var j = 0; j < this.tempSelectedArr.length; j++) {
            this.selectedArr = Object.values(this.tempSelectedArr[j]);
          }
          //alert(this.tempSelectedArr);i
          console.log(this.selectedArr);
          //alert(this.selectedArr);
          this.selectedNum = parseInt(arr.data.action.num);
          this.url = arr.data.action.url;
          if (this.url == undefined) {
            this.url = "www.example.com";
          }
          this.byteCardUrl = arr.data.profile.bytecard;
          this.myView = arr.data.action.view;
          //alert(this.selectedNum);
          // this.selectedArr.pop();
          // this.selectedArr.push(Object.values(this.tempSelectedArr));
          // alert(this.selectedArr[0])
        }
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
.logoutbtn {
  font-size: 15px;
  position: absolute;
  margin-left: 15%;
  margin-top: -4%;
  border: 2px solid #4caf50;
  color: black;
  border-radius: 15px;
  padding: 5px;
}
.logoutbtn:hover {
  background-color: #4caf50;
  color: white;
}
.noitem {
  font-size: 70px;
  text-align: center;
  margin-top: 70%;
}
.notext {
  font-size: 30px;
}
.companylogo {
  width: 150px;
  height: 45px;
}
.dotalign {
  font-size: 20px;
  margin-left: 16%;
  margin-top: -3.5%;
  position: absolute;
  cursor: pointer;
}
.dotsalign {
  font-size: 20px;
  margin-left: 33.4%;
  margin-top: -4%;
  position: absolute;
  cursor: pointer;
}
.myadmin {
  margin-top: 5%;
}
.myimg {
  width: 23px;
  height: 20px;
}
.lmyimg {
  width: 27px;
  height: 27px;
}
.ss1 {
  color: #383838;
  font-size: 20px;
}
.ss2 {
  color: #878787;
}
.l1 {
  display: inline-block;
  width: 30px;
  text-align: left;
}
.l2 {
  display: inline-block;
  width: 40px;
  text-align: left;
}
.content {
  overflow: hidden;
}
.myradio {
  position: absolute;
  margin-left: -1%;
  margin-top: 5%;
}
.lpoint {
  margin-left: 0%;
}
.main {
  width: 100%;
}
.linkth {
  margin-left: 10px;
}
th {
  width: 30px;
}
.linkimg {
  margin-right: 0%;
}
.selectbtn {
  margin-left: -20%;
}
.lm {
  margin-left: -7%;
}
.mycard {
  width: 55vh;
  height: 100vh;
}
.tfw {
  width: 500px;
}
.adminhead {
  margin-top: 0%;
  margin-bottom: 20%;
  margin-left: 35%;
  font-size: 30px;
  margin-bottom: 10%;
}
.mainlogo {
  font-size: 35px;
  margin-left: -20%;
  cursor: pointer;
  margin-bottom: 10%;
  margin-right: -20%;
}
.dots {
  margin-left: 95%;
  margin-top: -25%;
  height: 20%;
  cursor: pointer;
}
.dot {
  height: 65%;
  margin-left: 5050%;
  cursor: pointer;
  margin-top: -100px;
}
h4 {
  margin-left: 2%;
  margin-top: 2%;
}
.rec {
  margin-left: 2%;
  margin-top: 2%;
  color: #cccccc;
  font-size: 12px;
  line-height: 16px;
}
.rbtn {
  height: 22px;
  width: 22px;
  margin-top: 0%;
  background-color: #ffffff;
  border: 1px solid #bebebe;
}
.containerDiv {
  margin-top: 0%;
}
.mainElement {
  margin-top: 2%;
  background-color: #ffffff;
  position: relative;
}
.s1 {
  margin-left: 1%;
  border: 2px solid #bebebe;
  width: 35%;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  text-align: left;
}
.mainDiv {
  text-align: center;
  padding-top: 2%;
}
p {
  font-size: 12px;
  margin-bottom: -2%;
  color: #6f6f6f;
}
@media only screen and (max-width: 365px) {
  .dot {
    height: 65%;
    margin-left: -500%;
    cursor: pointer;
    margin-top: -20px;
  }
}
@media only screen and (max-width: 550px) {
  .mainDiv {
    text-align: center;
    padding-top: 5%;
  }
  .s1 {
    width: 80%;
  }
  .containerDiv {
    margin-top: 7%;
  }
  .mainElement {
    margin-top: 7%;
  }
  .dots {
    margin-top: -25%;
  }
  .dot {
    height: 65%;
    margin-left: 235px;
    cursor: pointer;
    margin-top: -20px;
  }
}
@media only screen and (max-width: 750px) {
  .mainDiv {
    text-align: center;
    padding-top: 5%;
  }
  .s1 {
    width: 70%;
  }
  .containerDiv {
    margin-top: 4%;
  }
  .mainElement {
    margin-top: 3%;
  }
  .tfw {
    width: 300px;
  }
}
@media only screen and (max-width: 1000px) {
  .dot {
    height: 65%;
    margin-left: 235px;
    cursor: pointer;
    margin-top: -20px;
  }
  .myradio {
    margin-left: -2%;
    margin-top: 3%;
  }
  .dotalign {
    font-size: 20px;
    margin-left: 15%;
    margin-top: -3.5%;
    position: absolute;
    cursor: pointer;
  }
  .dotsalign {
    font-size: 20px;
    margin-left: 32.4%;
    margin-top: -4%;
    position: absolute;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1200px) {
  .dot {
    height: 65%;
    margin-left: 310px;
    cursor: pointer;
    margin-top: -20px;
  }
}
input[value="1"]:checked ~ .one {
  background-color: #025f8d;
  color: white;
}
input[value="2"]:checked ~ .two {
  background-color: #025f8d;
  color: white;
}
input[value="3"]:checked ~ .three {
  background-color: #025f8d;
  color: white;
}
input[value="4"]:checked ~ .four {
  background-color: #025f8d;
  color: white;
}
input {
  cursor: pointer;
}
@media only screen and (max-width: 450px) {
  /* .mainlogo {
    margin-top: 5%;
    margin-left: -80%;
  } */
  .tfw {
    width: 220px;
  }
  .dot {
    margin-left: 240px;
    margin-top: -23%;
  }
}
@media only screen and (min-width: 700px) {
  .mainlogo {
    margin-top: -5%;
  }
}
.dis {
  color: #383838;
  margin-left: 10%;
}
.fsm {
  margin-left: 10%;
  color: #878787;
  white-space: nowrap;
}
.ldis {
  color: #383838;
  margin-left: 3%;
}
.lfsm {
  margin-left: 3%;
  color: #878787;
  white-space: nowrap;
}
.mdis {
  color: #383838;
  margin-left: 4%;
}
.mfsm {
  margin-left: 4%;
  color: #878787;
  white-space: nowrap;
}
.point {
  background-color: white;
  border: none;
  border-bottom: 2px solid #f8f8f8;
  width: 120%;
}
.point1 {
  margin-left: -7%;
}
@media only screen and (max-width: 365px) {
  .dot {
    margin-left: 2920%;
    margin-top: -1200%;
  }
  .dots {
    margin-left: 96%;
  }
  .myradio {
    position: absolute;
    margin-left: -7%;
    margin-top: 8%;
  }
}
@media only screen and (max-width: 700px) {
  .myradio {
    margin-left: -3%;
    margin-top: 4%;
  }
}

@media only screen and (max-width: 400px) {
  .myradio {
    position: absolute;
    margin-left: -7%;
    margin-top: 8%;
  }
}
@media only screen and (max-width: 750px) {
  .dotalign {
    font-size: 20px;
    margin-left: 32%;
    margin-top: -6%;
    position: absolute;
    cursor: pointer;
  }
  .dotsalign {
    font-size: 20px;
    margin-left: 66.7%;
    margin-top: -6%;
    position: absolute;
    cursor: pointer;
  }
  .logoutbtn {
    margin-left: 30%;
    margin-top: -10%;
  }
}
@media only screen and (max-width: 400px) {
  .dot {
    height: 65%;
    margin-left: 0%;
    cursor: pointer;
    margin-top: -20px;
  }
  .mycard {
    height: 100vh;
    width: 50vh;
  }
  .dotalign {
    font-size: 20px;
    margin-left: 29%;
    margin-top: -10%;
    position: absolute;
    cursor: pointer;
  }
  .dotsalign {
    font-size: 20px;
    margin-left: 63.5%;
    margin-top: -11%;
    position: absolute;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1000px) {
  .myradio {
    margin-left: -1%;
    margin-top: 2.3%;
  }
}
template {
  font-family: "serif";
}
@media only screen and (max-width: 415px) {
  .logoutbtn {
    margin-left: 25%;
    margin-top: -16%;
  }
}
@media only screen and (max-width: 380px) {
  .logoutbtn {
    margin-top: -18%;
  }
}
@media only screen and (max-width: 330px) {
  .logoutbtn {
    font-size: 10px;
  }
}
</style>