<template>
<div class="pa-4">

  <h2>Byte NFC</h2>
  <h4>Sign Up for New Account</h4>
  <v-card class="sign-up-form mt-4">
    <v-card-text>
      <v-form ref="form" v-model="isFormValid">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field variant="outlined" label="Email*" v-model="form.email"
                          :rules="[v => !!v || 'Email Required', v => Constants.EmailPattern.test(v) || 'Invalid Email']"/>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <v-text-field variant="outlined" label="Password*" v-model="form.password" @click:append-inner="showPassword = !showPassword"
                          :rules="[v => !!v || 'Please enter Password', v => v.length >= 8 || 'Password must be at least 8 characters']"
                          :append-inner-icon="showPassword?'mdi-eye':'mdi-eye-off'" :type="showPassword?'text':'password'"/>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field variant="outlined" label="Retype Password*" v-model="form.password_confirmation"
                          :rules="[v => !!v || 'Please enter Password', v => v === form.password || 'Confirm Password should be same as Password']"
                          @click:append-inner="showConfirmPassword = !showConfirmPassword" :type="showConfirmPassword?'text':'password'"
                          :append-inner-icon="showConfirmPassword?'mdi-eye':'mdi-eye-off'"/>
          </v-col>
          <v-btn style="width: 96%;" class="mx-auto mb-2 bg-success" @click="handleSubmit"
                 :loading="formLoading">Sign Up</v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
-
  <Snackbar :toaster="toaster" @close="toaster.enable=false"/>
</div>
</template>

<script>
import Constants from "@/constants/Constants";
import axios from 'axios';
import Snackbar from "@/common/Snackbar";
import {wsLinkA} from "@/vue-apollo";

export default {
  name: "SignUp",
  components: {Snackbar},
  data(){
    return{
      form: {email:'', password:'', password_confirmation:''},
      showPassword: false,
      showConfirmPassword: false,
      formLoading: false,
      isFormValid: false,
      Constants,
      toaster: {enable: false}
    }
  },
  methods:{
    handleSubmit() {
      if(this.$refs.form.validate() && this.isFormValid) this.signup()
    },
    signup(){
      this.formLoading = true;
      let request = {
        ...this.form, promo_code: '',
        product_id: Constants.ByteNfcProductId,
        hwId: this.$route.params.id                // 9188480A
      }
     axios.post(Constants.api_url+'register', request).then(response => {
        if(response.data.status){
          this.toaster = {enable:true, color:'green', message: `Sign Up Successful`};
          this.login(request);
        }else this.formLoading = false;
      }).catch(e => console.log('Register API error', e))
    },
    login({email, password, product_id, hwId}){
      axios.post(Constants.nfc_api_url+'login', {email, password, productId:product_id, hwId}).then(response => {
        if(response.data.status) {
          let user = response.data.data;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', user.token);
          // this.$emit('setUser', user);
          /*TODO: Close socket connection & reconnect will ensure a new connection with new token */
          wsLinkA.subscriptionClient.close();
          this.$router.push(`/${hwId}/profile`).then(() => this.formLoading = false);
        } else {
          this.toaster = {enable:true, color:'red', message: response.data.message};
          this.formLoading = false;
        }
      }).catch(e => console.log('login error', e))
    },
  }
}
</script>

<style scoped>
@media only screen and (min-width: 768px){
  .sign-up-form{
    width: 600px;
  }
}
</style>