<template>
    <div id="toaster">
        <v-snackbar :color="toaster.color" v-model="enable" :timeout="3000" top right>
            <span v-html="toaster.message?toaster.message:'Something went Wrong'" class="text-white"></span>
            <!--<v-btn icon @click="toaster.enable = false">
                <v-icon>close</v-icon>
            </v-btn>-->
        </v-snackbar>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: 'Snackbar',
    props: ['toaster'],
    computed: {
      enable:{
        get() { return this.toaster.enable },
        set(val) { if(!val) this.$emit('close') }
      }
    }
}
</script>

<style scoped>

</style>
