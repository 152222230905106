<template>
    <div>
        <h1>Card Not Found</h1>
    </div>
</template>
<style scoped>
div
{
    text-align: center;
    margin-top: 20%;
}
@media only screen and (max-width:450px)
{
    div{
        margin-top: 55%;
    }
}
</style>