<template>
  <div>
    <div v-if="redirectNum == 3">
      <div v-if="showAdmin">
        <div>
          <div class="up">
            <div class="up2">
              <div class="compDiv">
                <img class="mycomp" :src="compLogo" alt="mylogo" />
                <!-- <img class="mycomp" :src="require('@/assets/reimg.png')"> -->
              </div>
            </div>
            <p class="oname">{{ oname }}</p>
            <p class="owner">{{ designation }}</p>
            <p class="cname">{{ cname }}</p>
          </div>
          <div v-if="arr1.length === 0">
            <noItem />
            <div class="down1">
              <p>
                Powered by
                <img
                  :src="require('@/assets/NFCLogo.png')"
                  alt="nopic"
                  class="poweredBy"
                />
              </p>
            </div>
          </div>
          <div v-else-if="myView == 1">
            <div v-for="(i, index) in arr1" :key="i" class="grid-container">
              <a @click="redirectWa(arr1[index][2])">
                <div v-if="arr1[index][0] == 'Whatsapp'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/waimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
              <a :href="arr1[index][2]">
                <div v-if="arr1[index][0] == 'Location'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/locationimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
              <a @click="redirectLinkSame(arr1[index][2])">
                <div v-if="arr1[index][0] == 'Link'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/linkimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
              <a @click="redirectCall(arr1[index][2])">
                <div v-if="arr1[index][0] == 'Call'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/callimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
              <a :href="arr1[index][2]">
                <div v-if="arr1[index][0] == 'Save Contact'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/vcardimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
              <a :href="arr1[index][2]" target="_blank" download>
                <div v-if="arr1[index][0] == 'Download'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/downloadimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
              <a @click="redirectMail(arr1[index][2])">
                <div v-if="arr1[index][0] == 'Email'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/mailimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
              <a @click="redirectLinkSame(arr1[index][2])">
                <div v-if="arr1[index][0] == 'Website'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/websiteimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
              <a @click="redirectLinkSame(arr1[index][2])">
                <div v-if="arr1[index][0] == 'Reviews'" class="grid-Item">
                  <table>
                    <tr class="grid"><img :src="require('@/assets/reviewimg.png')" class="mainimg"></tr>
                    <tr class="grid"><span class="opname">{{ arr1[index][1] }}</span></tr>
                  </table>
                </div>
              </a>
            </div>
             <div class="down1" v-if="myView == 1 && arr1.length !== 0 && arr1.length <= 6">
            <p>
              Powered by
              <img
                :src="require('@/assets/NFCLogo.png')"
                alt="nopic"
                class="poweredBy"
              />
            </p>
          </div>
          <div class="down" v-if="myView == 1 && arr1.length > 6">
            <p>
              Powered by
              <img
                :src="require('@/assets/NFCLogo.png')"
                alt="nopic"
                class="poweredBy"
              />
            </p>
          </div>
          </div>
          <div v-else-if="myView == 2" class="outermost">
            <div v-for="(i, index) in arr1" :key="i" class="outer">
              <div class="main" v-if="arr1[index][0] == 'Whatsapp'">
                <a @click="redirectWa(arr1[index][2])">
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                      <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                              <img :src="require('@/assets/waimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else-if="arr1[index][0] == 'Location'">
                <!-- <span class="disp">{{ (url2 = arr1[index][2]) }}</span> -->
                <a :href="arr1[index][2]">
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                      <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                              <img :src="require('@/assets/locationimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else-if="arr1[index][0] == 'Link'">
                <!-- <span class="disp">{{ (url3 = arr1[index][2]) }}</span> -->
                <a @click="redirectLinkSame(arr1[index][2])">
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                      <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                             <img :src="require('@/assets/linkimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else-if="arr1[index][0] == 'Call'">
                <!-- <span class="disp">{{ (url4 = "tel:" + arr1[index][2]) }}</span> -->
                <a @click="redirectCall(arr1[index][2])">
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                      <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                              <img :src="require('@/assets/callimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else-if="arr1[index][0] == 'Email'">
                <!-- <span class="disp">{{
                (url5 = "mailto: " + arr1[index][2])
              }}</span> -->
                <a @click="redirectMail(arr1[index][2])">
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                     <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                              <img :src="require('@/assets/mailimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else-if="arr1[index][0] == 'Website'">
                <!-- <span class="disp">{{
                (url5 = "mailto: " + arr1[index][2])
              }}</span> -->
                <a @click="redirectLinkSame(arr1[index][2])">
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                      <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                              <img :src="require('@/assets/websiteimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else-if="arr1[index][0] == 'Reviews'">
                <!-- <span class="disp">{{
                (url5 = "mailto: " + arr1[index][2])
              }}</span> -->
                <a @click="redirectLinkSame(arr1[index][2])">
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                      <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                             <img :src="require('@/assets/reviewimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else-if="arr1[index][0] == 'Save Contact' || arr1[index][0] == 'Vcard'">
                <!-- <span class="disp">{{ url5 = "mailto: " + arr1[index][2] }}</span> -->
                <a :href="arr1[index][2]">
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                     <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                              <img :src="require('@/assets/vcardimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else-if="arr1[index][0] == 'Download'">
                <!-- <span class="disp">{{ url5 = arr1[index][2] }}</span> -->
                <a :href="arr1[index][2]" target="_blank" download>
                  <b-alert
                    show
                    :variant="alertCol"
                    @click="openForm(index)"
                    class="point"
                  >
                    <span class="names">
                     <table>
                        <tr>
                          <th rowspan="2">
                            <label>
                              <img :src="require('@/assets/downloadimg.png')" class="mainimg">
                            </label>
                          </th>
                          <td>
                            <span class="s1">{{ arr1[index][1] }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="s2">{{ arr1[index][2] }}</span>
                          </td>
                        </tr>
                      </table>
                    </span>
                  </b-alert>
                </a>
              </div>
              <div class="main" v-else>
                <b-alert
                  show
                  :variant="alertCol"
                  @click="openForm(index)"
                  class="point"
                >
                  <span class="names">
                    <span class="dis">{{ arr1[index][1] }}</span>
                  </span>
                </b-alert>
              </div>
              <div></div>
            </div>
          </div>
          <div class="down1" v-if="myView == 2 && arr1.length !== 0 && arr1.length <= 1">
            <p>
              Powered by
              <img
                :src="require('@/assets/NFCLogo.png')"
                alt="nopic"
                class="poweredBy"
              />
            </p>
          </div>
          <div class="down" v-if="myView == 2 && arr1.length > 1">
            <p>
              Powered by
              <img
                :src="require('@/assets/NFCLogo.png')"
                alt="nopic"
                class="poweredBy"
              />
            </p>
          </div>
          <!-- <h2 class="admin">Are you <router-link :to="myUrl">Admin</router-link>?</h2> -->
        </div>
      </div>
      <!-- <div v-if="formDialog">
      <addrec
        :num="myNum"
        :arr="arr1"
        @closeDialog="formDialog = false"
      />
    </div> -->
      <!-- <div v-if="mytemp">
      <addrec/>
    </div> -->
    </div>
    <div v-if="showErr">
      <err />
    </div>
    <Snackbar :toaster="toaster" @close="toaster.enable=false"/>
  </div>
</template>
<script>
//import vCardsJS from "vcards-js";
import App from "../App.vue";
//import addrec from "./userShow.vue";
import err from "./ErrorLog.vue";
import noItem from "./noItems.vue";
import axios from 'axios';
import Constants from "@/constants/Constants";
import Snackbar from "@/common/Snackbar";
const routes = {
  "/App": App,
};
export default {
  name: "userView",

  components: {
    Snackbar,
    //addrec,
    err,
    noItem,
  },
  methods: {
    // generateVFC() {
    //   var vCard = vCardsJS();

    //   vCard.companyName = "Avinashi Group";
    //   vCard.address = "Udhna";
    //   vCard.personName = "Kushal";
    //   vCard.designation = "Worker";
    //   vCard.mobile = "9106884674";
    //   vCard.email = "kushalmajiwala1212@gmail.com";

    //   //vCard.saveToFile('./myvcard.vcf');
    //   console.log(vCard.getFormattedString());
    // },
    redirectWa(num) {
      window.location.href = "https://wa.me/91" + num;
    },
    redirectCall(num) {
      window.location.href = "tel: " + num;
    },
    redirectMail(mid) {
      window.location.href = "mailto: " + mid;
    },
    redirectLinkSame(lurl) {
      if(lurl.includes('http'))
      {
        window.location.href = lurl;
      }
      else
      {
        window.location.href = "https://" + lurl;
      }
    },
    showLogin() {
      //this.showRec = false;
      this.$route.push({ name: "App" });
    },
    openForm(index) {
      //this.formDialog = true;
      this.myNum = index;
    },
    getData(hwId) {
      axios.post(Constants.nfc_api_url+'get', {hwId}).then(response => {
        if(response.data.status){
          // eslint-disable-next-line no-prototype-builtins
          if(response.data.data.action.hasOwnProperty('num'))
            this.printData(response.data);
          else this.$router.push(`/${hwId}/admin`);
        }else if(response.data.code === Constants.ApiCode.NotLinkCode){
          this.toaster = {enable:true, color:'red', message: response.data.message};
          this.$router.push(`/${hwId}/sign-up`);
        }else if(response.data.code === Constants.ApiCode.IncompleteProfile){
          let user = localStorage.user ? JSON.parse(localStorage.user) : null;
          if(user){
            this.$router.push(`/${hwId}/profile`);
            localStorage.setItem('user', JSON.stringify({...user, is_completed:false}));
          } else this.$router.push(`/${hwId}/admin`);
        }else if(response.data.code === Constants.ApiCode.InvalidCode){
          this.toaster = {enable:true, color:'red', message: response.data.message};
        }
      }).catch(e => console.log('NFC /get API error', e))

     /* var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch("https://api.bytecard.in/api/nfc/get", requestOptions)
        .then((response) => response.text())
        .then((result) => this.printData(result))
        .catch((error) => console.log(error)); */

      this.myUrl = "/" + this.$route.params.id + "/admin";
    },
    printData(result) {
      this.arr = result;
     /* this.arr = JSON.parse(result);
      if (this.arr.status == false) {
        this.showErr = true;
        alert(this.arr.message);
        console.log(this.arr);
        this.showAdmin = false;
      } else { */
        //console.log(arr);
        //console.log(Object.values(this.tempArr[5]));
        this.tempArr = Object.values(this.arr.data.options.items);
        console.log(this.tempArr);
        for (var i = 0; i < this.tempArr.length; i++) {
          this.arr1.push(Object.values(this.tempArr[i]));
        }
        //console.log(this.arr1);
        // this.num1 = false;
        // this.num2 = true;
        this.myInfo = Object.values(this.arr.data.action.data);
        for (var j = 0; j < this.myInfo.length; j++) {
          this.selectedArr = Object.values(this.myInfo[j]);
        }
        //alert(this.type + " " + this.value);
        this.redirectNum = parseInt(this.arr.data.action.num);

        this.oname = this.arr.data.profile.owner_name;
        this.cname = this.arr.data.profile.company_name;
        this.designation = this.arr.data.vcard.designation;
        this.type = this.selectedArr[0];
        this.value = this.selectedArr[2];
        this.myLink = this.arr.data.action.url;
        if(this.myLink == undefined)
        {
          this.myLink = "www.example.com";
        }
        this.firstOptionUrl = this.arr.data.profile.bytecard;
        this.compLogo = this.arr.data.profile.logo;
        this.view = this.arr.data.action.view;
        if(this.view == 'grid')
        {
          this.myView = 1;
        }
        else
        {
          this.myView = 2;
        }
        //alert(this.type + " " + this.value);
        if (this.redirectNum == 4) {
          if (this.type == "Link") {
            window.location.href = "https://" + this.value;
          } else if (this.type == "Whatsapp") {
            window.location.href = "https://wa.me/" + this.value;
          } else if (this.type == "Call") {
            window.location.href = "tel:" + this.value;
          } else if (this.type == "Location") {
            window.location.href = this.value;
          } else if (this.type == "Email") {
            window.location.href = "mailto: " + this.value;
          } else if (this.type == "Save Contact") {
            window.location.href =
              "https://api.bytecard.in/api/nfc/vcard/download?hwId=" +
              this.$route.params.id;
          } else if (this.type == "Download") {
            window.open(this.value, "download");
          } else if (this.type == "Website") {
            window.location.href = "https://" + this.value;
          } else if (this.type == "Reviews") {
            window.location.href = "https://" + this.value;
          }
        } else if (this.redirectNum == 2) {
          window.location.href = "https://" + this.myLink;
        } else if (this.redirectNum == 1) {
          window.location.href = this.firstOptionUrl;
        }
        this.showErr = false;
        this.showAdmin = true;
      // }
    }
  },
  mounted() {
    /* eslint-disable no-mixed-spaces-and-tabs */
    window.addEventListener("hashchange", () => {
      this.currentPath = window.location.hash;
    });
    /* eslint-enable no-mixed-spaces-and-tabs */
  },
  created() {
    this.getData(this.$route.params.id);
  },
  data: () => ({
    // currentPath: window.location.hash,
    //arr1: [["Link", "Google", "https://www.google.com"], ["Call", "Home", "9106884674"], ["Call", "Office", "9879364472"], ["Whatsapp", "Office", "9106884674"], ["Whatsapp", "Home", "7878962975"], ["Location", "Location", "https://maps.app.goo.gl/vKBKrocP7XaeJFH19"], ["Whatsapp", "Shop", "9106884674"]],
    arr1: [],
    //arr2: ["WhatsApp", "Call", "Link", "Location"],
    dialog: false,
    key: "",
    val: "",
    selectedVal: "",
    myNum: 0,
    //formDialog: false,
    //checktoggle: false,
    //showRec: true,
    myUrl: "",
    url1: "",
    url2: "",
    url3: "",
    url4: "",
    url5: "",
    arr: "",
    showErr: false,
    showAdmin: true,
    alertCol: "",
    // iconArr: [
    //     require("@/assets/wa.png"),
    //     require("@/assets/call.png"),
    //     [require("@/assets/link1.png"), require("@/assets/link2.png")],
    //     require("@/assets/location.png"),
    //   ],
    tempArr: [],
    designation: '',
    mytemp: false,
    redirectNum: 0,
    myInfo: [],
    type: "",
    value: "",
    selectedArr: [],
    myLink: "",
    firstOptionUrl: "",
    compLogo: "",
    oname: "",
    cname: "",
    myView: 1,
    view: '',
    toaster: {enable: false}
  }),
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || "/"];
    },
  },
};
</script>

<style scoped>
.opname{
  font-size: 12px;
  color: black;
  text-align: center;
}
.grid{
  display: block;
  width: 80px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
.grid-Item{
  display: inline-block;
}
.oname{
  color: white;
  margin-top: 1%;
  font-weight: bolder;
  font-size: 18px;
}
.owner{
  color: white;
  font-weight: 100;
  font-style: normal;
  font-size: 12px;
  margin-top: 1%;
}
.cname{
  color: white;
  margin-top: -1%;
  font-size: 12px;
}
.mainimg{
  width: 42px;
  height: 42px;
}
i {
  font-weight: bolder;
}
.myimg {
  width: 23px;
  height: 20px;
}
.lmyimg {
  height: 27px;
  width: 27px;
}
label {
  display: inline-block;
  width: 50px;
  margin-right: 10px;
  text-align: left;
}
.s1{
  color: #383838;
  font-size: 17px;
  white-space: nowrap;
}
.s2{
  color: #A4A4A4;
  font-size: 12px;
  white-space: nowrap;
  display: block;
}
label {
  /* display: inline-block; */
  width: 50px;
  text-align: left;
}
.point {
  background-color: #f8f8f8;
  border: 1px solid #ECECEC;
  font-family: noto-sans;
  font-size: 14px;
  color: #2e2e2e;
  border-radius: 0px;
}
.poweredBy {
  height: 3%;
  width: 7%;
}
.mylink {
  margin-left: 12%;
}
.dis {
  margin-left: 10%;
}
a {
  text-decoration: none;
}
.disp {
  display: none;
}
.myicon1 {
  margin-top: -5%;
  margin-right: 0%;
  margin-left: -6%;
}
.myicon {
  margin-top: -5px;
  margin-right: -3%;
}
.badgepos {
  margin-top: -35px;
}
.names {
  margin-left: 0%;
  font-size: 25px;
  font-family: "Noto Sans";
}
.new-chat-window {
  position: relative;
  width: 600px;
  display: block;
  margin: 10px auto;
  text-align: center;
  z-index: 2;
  margin-top: 5%;
  background-color: white;
}

.new-chat-window .fa {
  position: absolute;
  top: 13px;
  left: 10px;
}

input:focus {
  outline-color: rgb(170, 208, 228);
}

.new-chat-window-input {
  border: 1px solid #ccc;
  line-height: 40px;
  padding-left: 50px;
  z-index: 1;
  width: 600px;
  border-radius: 7px;
  height: 40px;
  background-color: white;
  border: 1px solid #c2ebff;
}
.headingdiv {
  text-align: center;
  margin-top: 5%;
}
.btn {
  height: 50px;
  background-color: rgb(185, 203, 203);
  width: 230px;
  border-radius: 7px;
  font-size: 20px;
  border: 2px solid rgb(182, 217, 217);
  margin-top: 5%;
  background-color: #0778b1;
  color: white;
}
.btn:hover {
  background-color: rgb(182, 217, 217);
}
@media only screen and (max-width: 700px) {
  .new-chat-window {
    width: 400px;
    margin-top: 10%;
  }
  .new-chat-window-input {
    width: 400px;
  }
  .headingdiv {
    margin-top: 10%;
    margin-bottom: 5%;
  }
}
@media only screen and (max-width: 500px) {
  .new-chat-window {
    width: 270px;
    margin-top: 15%;
  }
  .new-chat-window-input {
    width: 270px;
  }
  .headingdiv {
    margin-top: 20%;
    margin-bottom: 10%;
  }
  .btn {
    margin-top: 15%;
    width: 180px;
  }
}
.loginlogo {
  float: left;
  height: 15px;
}
.d1 {
  padding: 5%;
  text-align: center;
}
.tf {
  height: 40px;
  width: 600px;
}
.selection {
  background-color: white;
  text-align: center;
  color: rgb(96, 88, 88);
  width: 100%;
  height: 15%;
  border: 1px solid rgb(59, 57, 57);
  cursor: pointer;
  font-size: 15px;
}
.tfw {
  width: 500px;
}
.up {
  /* height: 250px;
  margin-left: -11%;
  width: 120%;
  background: url("../assets/upperimg.png");
  background-repeat: no-repeat;
  background-size: 200% 200%;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 10%;
  border-radius: 0% 0% 120% 120%;
  overflow: hidden; */
  /* display: flex;
  justify-content: center; */
  background: url("../assets/frontimg.png");
  background-size: 100% 100%;
  text-align: center;
  /* margin-top: 0%; */
  padding-top: 5%;
  /* border-radius: 50% / 40%; */
  /* border-top-left-radius: 0%; */
  /* border-top-right-radius: 0%; */
  /* height: 280px; */
  background-repeat: no-repeat;
  width: 102%;
  height: 300px;
  margin: -1%;
  /* margin-left: -10%;  */
}
.up2{
  display: flex;
  justify-content: center;
}
.compDiv {
  background-color: white;
  margin-top: -2%;
  border-radius: 50%;
  height: 102px;
  width: 102px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; 
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.4);
}
.mycomp {
  width: 80%;
  height: auto;
}
.down {
  padding-bottom: 0px;
  width: 100%;
  height: 10%;
  text-align: center;
  margin-top: 0%;
}
.down1 {
  position: fixed;
  bottom: 0;
  padding-bottom: 0px;
  width: 100%;
  height: 10%;
  text-align: center;
}
.addbtn {
  margin-left: 80%;
  border: 1px solid;
  height: 60px;
  width: 50px;
  font-size: 50px;
  border-radius: 50%;
  background-color: rgb(93, 67, 198);
  font-size: 30px;
  color: white;
}
.addbtn:hover {
  background-color: rgb(222, 111, 83);
  color: white;
}
.main {
  margin-top: -4%;
  width: 50%;
  border-radius: 25px;
  padding-top: 2%;
  padding-bottom: 2%;
  overflow: hidden;
}
.main .product {
  display: flex;
  justify-content: left;
}
.main .badge {
  display: flex;
  justify-content: right;
}
.outermost {
  margin-top: 10%;
}
.outer {
  display: flex;
  justify-content: center;
}
.grid-container{
  display: inline-block;
  width: 370px;
  padding-top: 30px;
  margin-left: 40px;
  /* border: 1px solid red; */
  text-align: center;
}

.mainlogo {
  width: 30%;
}
@media only screen and (max-width: 1250px)
{
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 300px;
    margin-left: 80px;
  }
}
@media only screen and (max-width: 1140px)
{
  .grid-container{
    margin-left: 60px;
  }
}
@media only screen and (max-width: 1080px)
{
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 260px;
    margin-left: 60px;
  }
}
@media only screen and (max-width: 965px)
{
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 200px;
    margin-left: 95px;
  }
}
@media only screen and (max-width: 900px)
{
  .grid-container{
    margin-left: 80px;
  }
}
@media only screen and (max-width: 850px)
{
  .grid-container{
    margin-left: 60px;
  }
}
@media only screen and (max-width: 780px)
{
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 150px;
    margin-left: 70px;
  }
}
@media only screen and (max-width: 710px)
{
  .grid-container{
    margin-left: 60px;
  }
}
@media only screen and (max-width: 650px)
{
  .grid-container{
    margin-left: 40px;
  }
}
@media only screen and (max-width: 570px)
{
  .grid-container{
    margin-left: 30px;
  }
}
@media only screen and (max-width: 455px)
{
  .grid-container{
    margin-left: 0px;
  }
}
@media only screen and (max-width: 635px)
{
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 120px;
    margin-left: 60px;
  }
}
@media only screen and (max-width: 590px)
{
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 100px;
    margin-left: 60px;
  }
}
@media only screen and (max-width: 540px)
{
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 90px;
    margin-left: 50px;
  }
}
@media only screen and (max-width: 480px)
{
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 80px;
    margin-left: 50px;
  }
}
@media only screen and (max-width: 430px)
{
  .grid-container{
    margin-left: 70px;
  }
}
@media only screen and (max-width: 630px) {
  .main {
    width: 90%;
  }
  .tfw {
    width: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .tfw {
    width: 200px;
  }
  .tf {
    height: 30px;
    width: 300px;
  }
  .mainlogo {
    height: 10%;
    width: 20%;
  }
  label {
    width: 47px;
  }
  .grid-container{
    margin-left: 100px;
  }
}
@media only screen and (max-width: 730px) {
  .tf {
    width: 400px;
  }
  .up{
    padding-top: 8%;
  }
}
@media only screen and (min-width: 730px) {
  .poweredBy {
    height: 3%;
    width: 7%;
  }
}
@media only screen and (max-width: 450px) {
  .tf {
    width: 280px;
  }
  .mainlogo {
    width: 200px;
    height: 60px;
  }
  .poweredBy {
    height: 9%;
    width: 18%;
  }
  .down {
    margin-top: 15%;
  }
  .cname{
    margin-top: -4%;
  }
  .oname{
    margin-top: 7%;
  }
  .grid-container{
    display: inline-block;
    padding: 30px;
    width: 115px;
    margin-left: -1px;
  }
}
@media only screen and (min-width: 700px) {
  .mainlogo {
    margin-top: -5%;
  }
  .myicon1 {
    margin-top: -3%;
  }
}
@media only screen and (max-width: 600px) {
  .mainlogo {
    margin-top: -5%;
  }
  .myicon1 {
    margin-top: -3%;
  }
  .down {
    bottom: -10px;
  }
}
.admin {
  text-align: center;
  margin-top: 1.5%;
}
.point {
  cursor: pointer;
  border:none;
  background-color: white;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  margin-top: -2%;
}
@media only screen and (max-width: 400px)
{
  .up{
    padding-top: 13%;
  }
}
@media only screen and (max-width: 340px)
{
  .grid-container{
    margin-left: 20px;
    width: 60px
  }
}
@media only screen and (max-width: 300px)
{
  .grid-container{
    margin-left: 10px;
    width: 60px
  }
}
@media only screen and (max-width: 280px)
{
  .grid-container{
    margin-left: 5px;
    width: 60px
  }
}
</style>