import { createRouter, createWebHistory } from "vue-router";
import emptyComp from '../components/emptyComp.vue';
import userView from '../components/userView.vue';
import err from '../components/ErrorLog.vue';
import SignUp from "@/components/SignUp";
import Profile from "@/components/Profile";

const routes = [
    {path: '/:id/sign-up', component: SignUp},
    {path: '/:id/profile', component: Profile},
    { path: '/:id/admin', component: emptyComp},
    {path: '/:id', component: userView},
    {path: '/', component: err}
]
const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;