<template>
  <v-app class="mainDiv">
    <link href='https://fonts.googleapis.com/css?family=Noto Sans' rel='stylesheet'>
    <meta name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'/>
    <router-view/>
  </v-app>
</template>
<script>
export default {

}
</script>
<style scoped>
.mainDiv{
  font-family: 'Noto Sans';
}
i{
  color: green;
}
</style>

