<template>
  <div>

    <v-dialog v-model="uploadDialog">
      <v-card>
        <v-card-text>
        <div class="text-center my-2">
          <h4>Upload File</h4>
          <p class="text-grey-darken-1">Upload your file here to share</p>
        </div>
        <div class="pa-4">
          <div>
            <div class="text-center">
              <input id="file" @change="onSelectFile()" type="file" style="display: none" :accept="fileTypes"/>
              <div @click="openFile" class="mx-auto image-box">
                <div v-if="fileDataURI && file.type==='application/pdf'">
                  <div style="width: 250px; height: 250px;" class="mx-auto mb-4">
                    <img src="../../assets/pdf.png" alt="" class="w-100">
                  </div>
                  <v-btn @click.stop="" class="mx-auto w-50 d-block" :href="fileDataURI" target="_blank">
                    PREVIEW
                  </v-btn>
                </div>
                <div v-else-if="fileDataURI" class="mx-auto mb-4 with-image-box">
                  <cropper ref="cropper" @click.stop="" :src="fileDataURI" :auto-zoom="true"
                           image-restriction="stencil" style="width: 100%; height: 100%"/>
                </div>
                <v-icon v-else>mdi-cloud-upload</v-icon>
              </div>
              <div class="mt-6">
                <v-btn @click="closeDialog" class="col bg-grey-lighten-3 mr-4">CANCEL</v-btn>
                <v-btn @click="crop" :disable="!file" color="primary" class="col"
                       :loading="fileLoading">UPLOAD</v-btn>
              </div>
            </div>
          </div>
        </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Snackbar :toaster="toaster" @close="toaster.enable=false"/>
  </div>
</template>

<script>
import Constants from '../../constants/Constants';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Snackbar from "@/common/Snackbar";
import axios from 'axios';
export default {
  name: "UploadFile",
  props: ['dialog', 'fileTypes', 'maxSize'],
  emits: ['update:dialog'],
  components: {Snackbar, Cropper},
  data: function () {
    return {
      file: '',
      fileDataURI: '',
      fileLoading: false,
      toaster: {enable: false}
    }
  },
  computed:{
    uploadDialog:{
      get() { return this.dialog },
      set(val) { this.$emit('update:dialog', val) }
    }
  },
  methods: {
    openFile() {
      document.getElementById('file').click();
    },
    onSelectFile() {
      this.file = document.getElementById('file').files[0];
      this.fileDataURI = window.URL.createObjectURL(this.file);
    },
    crop() {
      const {canvas} = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        let cropFile = new File([blob], this.file.name);
        if(this.maxSize && cropFile.size > this.maxSize){
          this.toaster = {enable:true, color:'red', message: `File is too big! Please upload file with size less than ${this.maxSize / 1048576}MB`};
        } else{
          this.uploadFile(cropFile);
        }
      }, this.file.type);
    },
    uploadFile(cropFile) {
      this.fileLoading = true;
      let formData = new FormData();
      formData.append('image', cropFile);

      axios.post(Constants.api_url + 'upload', formData).then(res => {
        if(res.data.status) {
          this.$emit('fileResponse', res.data.data);
          this.closeDialog();
          this.toaster = {enable:true, color:'green', message: `File uploaded Successfully`};
        } else this.toaster = {enable:true, color:'green', message: res.data.message};
        this.fileLoading = false;
      }).catch(function (err) {
        console.log('Upload File Error', err);
        this.toaster = {enable:true, color:'red', message: `File Upload failed`};
        this.fileLoading = false;
      })
    },
    reset() {
      this.file = '';
      this.fileDataURI = '';
    },
    closeDialog() {
      this.reset();
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>
.image-box {
  height: 250px;
  width: 400px;
  border-radius: 10px;
  border: 1px dashed #e0e0e0;
  cursor: pointer;
  text-align: center;
  line-height: 250px;
}
.with-image-box{
  height: 250px;
  width: 350px;
}
@media only screen and (max-width: 600px) {
  .image-box{
    width: 100%;
  }
  .with-image-box{
    width: 100%;
  }
}
.icon-cloud {
  font-size: 50px;
  color: grey;
}

.w-100 {
  width: 100% !important;
  height: 100% !important;
}

/deep/ .vue-handler-wrapper__draggable {
  cursor: unset !important;
  opacity: unset !important;
}

/deep/ .vue-simple-handler {
  cursor: unset !important;
  opacity: unset !important;
}
</style>
