<template>
    <div>
        <p>No Page Items Added</p>
    </div>
</template>
<style scoped>
p{
    font-size: 20px;
    font-weight: 500;
}
div
{
    text-align: center;
    margin-top: 7%;
}
@media only screen and (max-width:450px)
{
    div{
        margin-top: 40%;
    }
}
</style>