export default {
    base_url: process.env.VUE_APP_BASE_API_URL,
    api_url: process.env.VUE_APP_API_URL,
    nfc_api_url: 'https://api.bytecard.in/api/nfc/',
    ApiCode:{
        InvalidCode: 202,
        NotLinkCode: 205,
        IncompleteProfile: 207
    },
    ByteNfcProductId: 'a9ec6071-ab8c-479e-b3d0-ee0cbfc68ae8',
    EmailPattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}